import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0),
  },
  logo: {
    marginBottom: 24,
  },
  link: {
    textDecoration: 'none',
  }
}));

const Footer: React.FC = () => {
  const { site } = useStaticQuery<GatsbyTypes.FooterSiteMetadataQueryQuery>(graphql`
    query FooterSiteMetadataQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" style={{textAlign: 'center'}}>
        <a href="https://link-nt.co.jp" className={classes.link}>
          <StaticImage
            src="../images/corporate-logo.png"
            alt="Linknet"
            placeholder="none"
            className={classes.logo}
            width={240}
          />
        </a>
        <Typography variant="body2" color="textSecondary" align="center">
          Copyright &copy; {new Date().getFullYear()} {site?.siteMetadata?.title}
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
