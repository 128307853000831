import React from "react";
import { createStyles, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  home: {
    color: '#000000',
    textDecoration: 'none',
  },
}));

const Header: React.FC = () => {
  const { site } = useStaticQuery<GatsbyTypes.HeaderSiteMetadataQueryQuery>(graphql`
    query HeaderSiteMetadataQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const classes = useStyles();

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          <a href="/" className={classes.home}>{site?.siteMetadata?.title}</a>
        </Typography>
      </Toolbar>
    </>
  );
}

export default Header;
