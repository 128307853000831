import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

type Props = {
}

const Layout: React.FC<Props> = props => {
  const { children } = props;
  const theme = createMuiTheme({});
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header/>
        {children}
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}

export default Layout;
